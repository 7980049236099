<template>
    <div class="about">render</div>
</template>
<script>
export default {
    name: 'About',
    data() {
        return {
            events: null,
        };
    },
};
</script>
<style scoped></style>
